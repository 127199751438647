export default {
  type(t) {
    var e = {
      label: `text-gray-600 bg-gray-200`,
    };

    if (t == "LOST") {
      e.label = `text-red-200 bg-gradient-to-r from-pink-500 to-red-700`;
    } else if (t == "WIN") {
      e.label = `text-gray-200 bg-gradient-to-r from-blue-500 to-green-500`;
    }

    //BUY SELL
    if (t == "BUY") {
      e.label = `text-green-600 bg-green-200`;
    } else if (t == "SELL") {
      e.label = `text-red-600 bg-red-200`;
    }
    //TRANSACTION
    else if (t == "RECEIVE") {
      e.label = `text-green-600 bg-green-200`;
    } else if (t == "INCOME") {
      e.label = `text-blue-600 bg-blue-200`;
    } else if (t == "DEPOSIT") {
      e.label = `text-green-600 bg-green-200`;
    } else if (t == "WITHDRAW") {
      e.label = `text-red-600 bg-red-200`;
    } else if (t == "SEND") {
      e.label = `text-red-600 bg-red-200`;
    } else if (t == "DISTRIBUTION") {
      e.label = `text-pink-600 bg-pink-200`;
    } else if (t == "COMMISSION") {
      e.label = `text-pink-600 bg-pink-200`;
    } else if (t == "INVEST") {
      e.label = `text-purple-600 bg-purple-200`;
    }
    //MY INVEST
    else if (t == "INVESTING") {
      e.label = `text-blue-600 bg-blue-200`;
    }
    //WITHDRAWAL
    else if (t == "COMPLETED") {
      e.label = `text-green-600 bg-green-200`;
    } else if (t == "REJECTED") {
      e.label = `text-red-600 bg-red-200`;
    } else if (t == "CANCELED") {
      e.label = `text-red-600 bg-red-200`;
    } else if (t == "WAIT_FOR_AUDIT") {
      e.label = `text-pink-600 bg-pink-200`;
    } else if (t == "PROCESSING") {
      e.label = `text-purple-600 bg-purple-200`;
    }
    //DEPOSIT
    else if (t == "PENDING") {
      e.label = `text-pink-600 bg-pink-200`;
    } else if (t == "INCOMMING") {
      e.label = `text-purple-600 bg-purple-200`;
    }
    //LIQUIDITY
    else if (t == "ADD_LP") {
      e.label = `text-blue-600 bg-blue-200`;
    } else if (t == "REMOVE_LP") {
      e.label = `text-yellow-600 bg-yellow-200`;
    } else if (t == "SWAP_BUY") {
      e.label = `text-green-500 bg-green-100`;
    } else if (t == "SWAP_SELL") {
      e.label = `text-pink-600 bg-pink-200`;
    }

    return e;
  },
};
