<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
          <div class="p-4 mb-3 space-y-3">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
          <div
            class="w-full p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-3xl shadow-xl"
            v-if="user"
          >
            <div
              class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl text-yellow-50 bg-gray-900"
            >
              <div class="flex flex-col justify-center space-y-3">
                <div class="bg-gray-800 py-2 px-3 rounded">
                  <div class="text-base font-medium">
                    <i class="fad fa-wallet mr-2 z-0"></i>
                    <span
                      class="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-400"
                    >
                      {{ user.address.slice(0, 12) }}...{{
                        user.address.slice(-10)
                      }}
                    </span>
                  </div>
                </div>
                <div class="flex items-center space-x-3 gap-2">
                  <a
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 space-x-1 flex items-center justify-center text-blue-400"
                    v-bind:href="`https://${EXPLORER}/address/${user.address}`"
                    target="_blank"
                    ><i class="fad fa-link mr-2"></i> View on explorer</a
                  >

                  <div
                    class="flex items-center flex-shrink-0 space-x-1 no-underline cursor-pointer whitespace-nowrap hover:no-underline focus:no-underline active:no-underline text-blue opacity-80 hover:opacity-100 focus:opacity-100 text-blue-400"
                  >
                    <input
                      type="hidden"
                      id="text-address"
                      :value="user.address"
                    />
                    <a v-on:click="copyAddress()" class="text-sm font-medium"
                      ><i class="fad fa-copy"></i> {{ copymsg }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <span class="text-gray-500">Value(USD)</span>
                <h3 class="text-2xl text-shadow-white">
                  ≈ $ {{ usd(total_usd) }}
                </h3>

                <div class="flex flex-col sm:flex-row gap-2 mt-2">
                  <a
                    v-bind:href="'/user/' + user.refer_id"
                    v-if="user.refer_id"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                    ><i class="fad fa-handshake mr-1"></i> Invite by</a
                  >

                  <router-link
                    v-bind:to="'/transactions/' + user.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                    ><i class="fad fa-copy mr-1"></i> Transactions</router-link
                  >

                  <router-link
                    v-bind:to="'/deposit/' + user.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                    ><i class="fad fa-clipboard-list mr-1"></i> Deposit
                    history</router-link
                  >

                  <router-link
                    v-bind:to="'/withdraw/' + user.address"
                    class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-blue-400 mr-3"
                    ><i class="fad fa-clipboard-list mr-1"></i> Withdrawal
                    history</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div>Summary</div>

          <div
            class="grid row-gap-8 mx-auto space-y-1 lg:max-w-screen-lg grid-cols-2 w-full shadow-2xl subpixel-antialiased rounded bg-white border-black bg-opacity-5 p-2"
          >
            <div class="flex" v-for="b in summary" v-bind:key="b.id">
              <div class="w-60">
                <span
                  v-bind:class="
                    ' text-xs  inline-block py-1 px-2 rounded uppercase ml-2 ' +
                    b.label
                  "
                >
                  {{ b._id.type }}
                  ({{ b.count.toLocaleString() }})</span
                >
              </div>

              <span class="text-sm w-full text-right mt-1">
                {{ b.amount.toFixed(8) / 1 }} {{ b._id.currency }}
              </span>
            </div>
          </div>

          <div class="text-center">
            <div class="" v-on:click="hideSmall()" v-if="!hide.smallasset">
              <p class="cursor-pointer">
                <i class="fad fa-eye-slash"></i> Hide 0 balances
              </p>
            </div>
            <div class="active" v-on:click="hideSmall()" v-else>
              <p class="cursor-pointer">
                <i class="fad fa-eye"></i> Show 0 balances
              </p>
            </div>
          </div>

          <div class="p-1">
            <div v-if="funds && getCurrency">
              <div v-for="b in funds" v-bind:key="b.id" class="">
                <div class="w-full" v-if="b.balance > 0 || hide.smallasset">
                  <div
                    class="flex border-t border-l shadow-md border-opacity-25 py-1 px-3 bg-gray-500 rounded-lg bg-opacity-20 mb-2 items-center"
                  >
                    <div class="flex-none">
                      <img
                        class="h-4 inline mr-2"
                        :src="getCurrency[b.coin].logo"
                      />
                      <span class="mr-2">{{ b.coin }}</span>
                      <span class="text-xs">{{
                        getCurrency[b.coin].name
                      }}</span>
                      <br /><span
                        class="text-xs"
                        v-if="getCurrency[b.coin].price"
                        ><span class="font-bold"
                          >${{ getCurrency[b.coin].price.last_price }}</span
                        >
                        <span
                          class="text-green-500"
                          v-if="getCurrency[b.coin].price.change / 1 >= 0"
                        >
                          +{{ getCurrency[b.coin].price.change }}%
                        </span>
                        <span class="text-red-500" v-else>
                          {{ getCurrency[b.coin].price.change }}%
                        </span>
                      </span>
                      <span class="text-xs" v-else
                        ><span class="font-bold">$0</span>
                        <span class="text-green-500"> +0% </span>
                      </span>
                    </div>

                    <div class="flex-grow text-right">
                      <span class="">{{ amount(b.balance) }}</span
                      ><br /><span
                        class="text-gray-500 text-xs"
                        v-if="getCurrency[b.coin].price"
                      >
                        ≈ $
                        {{
                          usd(getCurrency[b.coin].price.last_price * b.balance)
                        }}</span
                      >
                      <span class="text-gray-500 text-xs" v-else>
                        ≈ $
                        {{ usd(0) }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="">
      <StickFooter />
    </footer>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import func from "@/widgets/func";
import { mapGetters } from "vuex";
import { EXPLORER } from "../../config";
import info from "@/api/info";
import nativeToast from "native-toast";
import label from "@/widgets/label";

export default {
  components: {
    ConnectWallet,
    StickFooter,
  },
  data() {
    return {
      address: this.$route.params.address || null,
      funds: null,
      user: null,
      summary: [],
      EXPLORER: EXPLORER,
      copymsg: "Copy Address",
      total_usd: 0,
      hide: JSON.parse(localStorage.getItem("HideAsset")) || {
        status: true,
        smallasset: true,
      },
    };
  },
  computed: mapGetters(["getCurrency"]),
  methods: {
    hideDelisted() {
      this.hide.status = !this.hide.status;
      localStorage.setItem("HideAsset", JSON.stringify(this.hide));
    },
    hideSmall() {
      this.hide.smallasset = !this.hide.smallasset;
      localStorage.setItem("HideAsset", JSON.stringify(this.hide));
    },
    amount(a) {
      return func.fixed8(a);
    },
    usd(a) {
      return func.fixed2(a);
    },
    caltotal() {
      var total = 0;
      for (let [coin, value] of Object.entries(this.funds)) {
        if (this.getCurrency[coin].price) {
          total =
            total + value.balance * this.getCurrency[coin].price.last_price;
        }
      }
      this.total_usd = total;
    },
    copyAddress() {
      let textToCopy = document.querySelector("#text-address");
      textToCopy.setAttribute("type", "text"); // hidden
      textToCopy.select();

      try {
        document.execCommand("copy");
        this.copymsg = "Copied";
        setTimeout(() => {
          this.copymsg = "Copy Address";
        }, 1000);
      } catch (err) {
        this.copymsg = "Oops, unable to copy";
      }

      /* unselect the range */
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    getdata() {
      info
        .user({ address: this.address })
        .then((res) => {
          this.ready = true;

          this.funds = res.data.funds;
          this.user = res.data.user;
          res.data.summary.forEach((e) => {
            var a = label.type(e._id.type);
            e.label = a.label;

            this.summary.push(e);
          });
          this.caltotal();
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    if (this.address) {
      this.getdata();
    } else {
      this.ready = true;
    }
  },
  updated() {
    this.caltotal();
  },
};
</script>
